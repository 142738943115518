"use client";

import { useEffect, useRef, useState } from "react";

export default function Image({
  src = "",
  alt = "",
  maxWidth = "",
  shouldLazyLoad = true,
  objectPosition = "center",
  cover = true,
  width = "",
  height = "",
  className = "",
  imgClassName = "",
  fillContainer = false,
  imgStyle = {},
  ...props
}) {
  const [loaded, setLoaded] = useState(true);
  const img = useRef(null);

  function onLoad() {
    setLoaded(true);
  }

  return (
    <picture
      className={`${className} ${fillContainer ? "h-full w-full" : ""} ${
        loaded ? "opacity-100" : "opacity-0"
      }`}
      onLoad={onLoad}
      {...props}
    >
      <source
        type="image/avif"
        media="(max-width: 500px)"
        srcSet={`${src}?fm=avif&w=1000`}
      />
      <source
        type="image/avif"
        media="(max-width: 1200px)"
        srcSet={`${src}?fm=avif&w=1200`}
      />
      <source
        type="image/avif"
        media="(min-width: 1201px)"
        srcSet={`${src}?fm=avif${maxWidth ? `&w=${maxWidth}` : ""}`}
      />
      <source
        type="image/webp"
        media="(max-width: 500px)"
        srcSet={`${src}?fm=webp&w=1000`}
      />
      <source
        type="image/webp"
        media="(max-width: 1200px)"
        srcSet={`${src}?fmwebpf&w=1200`}
      />
      <source
        type="image/webp"
        media="(min-width: 1201px)"
        srcSet={`${src}?fm=webp${maxWidth ? `&w=${maxWidth}` : ""}`}
      />
      <source
        type="image/jpeg"
        media="(max-width: 500px)"
        srcSet={`${src}?fm=jpg&w=1000`}
      />
      <source
        type="image/jpeg"
        media="(max-width: 1200px)"
        srcSet={`${src}?fmjpgf&w=1200`}
      />
      <source
        type="image/jpeg"
        media="(min-width: 1201px)"
        srcSet={`${src}?fm=jpg${maxWidth ? `&w=${maxWidth}` : ""}`}
      />
      <img
        fetchPriority={shouldLazyLoad ? "low" : "high"}
        ref={img}
        width={width}
        height={height}
        src={`${src}${maxWidth ? `?w=${maxWidth}` : ""}`}
        className={`${objectPosition === "center" ? "object-center" : ""} ${
          cover ? "object-cover" : ""
        } ${imgClassName} ${fillContainer ? "h-full w-full" : ""}`}
        alt={alt}
        loading={shouldLazyLoad ? "lazy" : "eager"}
        decoding="async"
        onLoad={onLoad}
        style={imgStyle}
      />
    </picture>
  );
}
